@tailwind components;

@layer components {
  .ctv-radio .ant-radio-input,
  .ctv-radio .ant-radio-inner {
    @apply h-5 w-5 border-secondary-black;
  }

  .ctv-radio:hover .ant-radio-inner {
    @apply border-primary-electric-300;
    //hover wasn't specified
  }

  .ctv-radio .ant-radio-checked .ant-radio-inner {
    @apply border-primary-electric-500 bg-white;
  }

  .ctv-radio .ant-radio-checked .ant-radio-inner::after {
    @apply h-3 w-3 transform-none bg-primary-electric-500;
    margin-block-start: -6px;
    margin-inline-start: -6px;
  }

  .ctv-radio .ant-radio-disabled .ant-radio-inner {
    @apply h-5 w-5 border-primary-gray-200 bg-primary-gray-25;
  }

  .ctv-radio:hover .ant-radio-disabled .ant-radio-inner {
    @apply border-primary-gray-200;
  }

  .ctv-radio.ctv-radio-cards {
    @apply inline-flex h-28 shrink grow basis-0 flex-col items-start  justify-start gap-1.5 rounded-lg border border-primary-gray-100 bg-white p-5;
    &::before {
      @apply content-none;
    }
    .card-text {
      @apply self-stretch text-base-sm font-normal leading-5 text-primary-gray-500;
    }
    .card-header {
      @apply text-base-sm font-semibold leading-tight text-secondary-black;
    }
  }

  .ctv-radio.ctv-radio-cards.ant-radio-button-wrapper-checked {
    @apply border-primary-electric-500 bg-primary-electric-50;
    .card-header {
      @apply text-base-sm font-semibold leading-tight text-primary-electric-500;
    }
    .card-text {
      @apply self-stretch text-base-sm font-normal text-primary-electric-300;
    }
  }

  .ctv-radio.ctv-radio-cards.ant-radio-button-wrapper-disabled {
    @apply bg-primary-gray-25;
    .card-text {
      @apply text-primary-gray-300;
    }
    .card-header {
      @apply text-primary-gray-600;
    }
  }

  .ctv-radio.ant-radio-button-wrapper.ctv-radio-text {
    @apply inline-flex h-10 w-full items-center justify-center gap-1.5 px-5 hover:text-primary-electric-500;
    span,
    span div {
      @apply text-base-sm font-medium leading-tight;
    }
  }

  .ctv-radio.ctv-radio-text.ant-radio-button-wrapper:first-child {
    @apply rounded-bl-full rounded-tl-full border;
  }

  .ctv-radio.ctv-radio-text.ant-radio-button-wrapper:last-child {
    @apply rounded-br-full rounded-tr-full;
  }

  .ctv-radio.ant-radio-button-wrapper.ctv-radio-text.ant-radio-button-wrapper-checked {
    @apply border-primary-electric-500 bg-primary-electric-50;
    span {
      @apply text-primary-electric-500;
    }
  }

  .ctv-radio.ant-radio-button-wrapper .check-icon {
    @apply hidden;
  }

  .ctv-radio.ant-radio-button-wrapper.ant-radio-button-wrapper-checked .check-icon {
    @apply block;
  }
}
