@tailwind components;

@layer components {
  .filter-container .ctv-checkbox .ant-checkbox-group {
    @apply flex flex-col;
  }

  .filter-container .ctv-checkbox .ant-checkbox-group .ant-checkbox-wrapper {
    @apply flex h-10 items-center px-1.5 text-base-sm font-normal leading-tight;
  }

  .filter-container .ctv-checkbox .ant-checkbox-group .ant-checkbox-wrapper > span:nth-child(2) {
    width: 292px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .filter-card .ant-card-body {
    overflow: hidden;
  }

  .filter-button {
    @apply h-10 cursor-pointer border border-primary-gray-100 bg-white px-3 text-base-sm font-medium leading-tight;
    border-radius: 8px 0 0 8px;

    &.active {
      @apply border-primary-punch-300;
      box-shadow: 0 0 0 4px rgba(238, 147, 165, 0.25);
      z-index: 1;
    }
  }

  .select-container .ctv-select.ant-select {
    @apply h-auto min-h-10;
  }

  .select-container .ctv-select.ant-select.filter-select .ant-select-selector {
    @apply h-auto min-h-10;
    border-radius: 0 8px 8px 0;
  }

  .select-container
    .filter-select.ctv-select.ant-select
    .ant-select-selector
    .ant-select-selection-overflow-item {
    @apply my-0.5;
  }

  .filter-select.ant-select.ant-select-outlined.ctv-select .ant-select-arrow {
    display: none;
  }

  .filter-select.ant-select.ant-select-outlined.ctv-select .ant-select-clear {
    @apply -mt-2;
    width: 54px;
  }

  .select-container .filter-select.ant-select .ant-select-selector:hover {
    border-color: theme('colors.primary.gray.100') !important;
  }

  .select-container .filter-select.ant-select.ant-select-focused .ant-select-selector {
    box-shadow: none !important;
  }

  .filter-select .ant-tag.ctv-chip {
    @apply h-auto whitespace-normal;
    word-break: break-word;
  }

  .filter-select .ant-tag .ctv-chip-text {
    @apply h-auto min-h-7;
  }

  .filter-clear {
    color: theme('colors.primary.electric.500');
  }

  .filter-dropdown-options .ant-dropdown-menu {
    .ant-dropdown-menu-item {
      @apply h-10 py-1.5 pl-1.5 pt-0;
    }
    .ant-dropdown-menu-item-group-title {
      @apply text-base-xs text-primary-gray-500;
    }
  }
}
