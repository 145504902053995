@tailwind components;

@layer components {
  .ctv-table {
    @apply overflow-x-scroll pb-0.5;

    .ant-table-title {
      @apply px-0;
    }

    .ant-table {
      scrollbar-color: inherit;
    }

    td.ant-table-column-sort {
      background: inherit;
    }

    .ant-table-column-sorters {
      justify-content: start;
    }

    .ant-table-thead .ant-table-cell,
    .ant-table-thead,
    .ant-table-column-sorters .ant-table-column-title {
      @apply min-w-32 bg-primary-gray-50 text-left text-base-xs font-medium text-primary-gray-500;
    }

    .ant-table-cell-content .ant-table-column-sorters .ant-table-column-title {
      flex: 0;
    }

    .ant-table-thead .ant-table-cell-fix-left-last {
      @apply border-r border-primary-gray-100;
    }

    .ant-table-row .ant-table-cell {
      @apply min-w-32 text-left text-base-sm font-normal leading-tight text-secondary-black;
    }
    .ant-table-row .ant-table-cell span {
      @apply text-left text-base-sm font-medium;
    }

    .ant-table-thead .ant-table-cell-fix-left .ant-table-cell-content {
      @apply text-base-sm font-normal
    }

    .ant-table-column-sort.ant-table-cell-fix-left {
      @apply bg-white;
      .ant-table-cell-row-hover {
        @apply bg-primary-gray-50;
      }
    }

    .ant-table-tbody .ant-table-row > .ant-table-cell-row-hover {
      @apply bg-primary-gray-50;
    }

    .ctv-expanded-row,
    .ctv-expanded-row .ant-table-cell-fix-right,
    .ctv-expanded-row .ant-table-cell-fix-left {
      @apply bg-primary-gray-25;
    }

    .ant-table-cell-fix-left-last {
      @apply border-r border-primary-gray-100;
    }
    .ant-table-cell-fix-right-first {
      @apply border-l border-primary-gray-100;
    }

    .ant-table-ping-right .ant-table-cell-fix-right-first::after {
      box-shadow: inset -14px 0 8px -8px rgba(0, 0, 0, 0.05);
    }

    .ant-table-ping-left .ant-table-cell-fix-left-last::after {
      box-shadow: inset 14px 0 8px -8px rgba(0, 0, 0, 0.05);
    }

    .ant-table-cell-ellipsis {
      text-overflow: unset;
      word-break: break-all;
      white-space: break-spaces;
    }
    .ant-table-thead > tr > th {
      @apply border-b-0 px-3 py-2;
    }

    .ant-table-tbody > tr > td {
      @apply border-b-0 border-t-0;
    }

    .ant-table-thead > tr > th::before {
      background-color: theme('colors.primary.gray.50') !important;
    }

    .ant-table-container table > thead > tr:first-child > *:first-child {
      @apply rounded-s-lg;
    }

    .ant-table-container table > thead > tr:first-child > *:last-child {
      @apply z-10 rounded-none bg-white;
    }

    .ant-table-container table > thead > tr:first-child > *:last-child::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      @apply rounded-e-lg bg-primary-gray-25;
    }
  }
}
