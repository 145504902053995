@tailwind components;

@layer components {
  .ctv-sider.ant-layout-sider {
    @apply shadow-sidebar;

    .ant-layout-sider-children {
      @apply m-0 flex flex-col;
    }
  }

  .ctv-sider.ant-layout-sider.ant-layout-sider-collapsed {
    @apply shadow-none;
  }
}
