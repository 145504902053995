@tailwind components;

@layer components {
  .sidebar-menu.ant-menu {
    @apply m-0 flex flex-1 flex-col p-0 pt-10;
  }

  .sidebar-menu.ant-menu .ant-menu-item {
    @apply -mx-0 flex w-auto items-center rounded-none bg-secondary-white;
  }

  .sidebar-menu.ant-menu .ant-menu-item .ant-menu-title-content,
  .sidebar-menu.ant-menu .ant-menu-item .ant-menu-item-icon {
    @apply text-base-sm font-medium text-primary-gray-500;
  }

  .sidebar-menu.ant-menu .ant-menu-item .ant-menu-item-icon {
    @apply flex-shrink-0;
  }

  .sidebar-menu.ant-menu .ant-menu-item:last-child {
    @apply mt-auto;
  }

  .sidebar-menu.ant-menu .ant-menu-item:hover {
    @apply rounded-none;
  }

  .sidebar-menu.ant-menu .ant-menu-item.ant-menu-item-selected .ant-menu-title-content,
  .sidebar-menu.ant-menu .ant-menu-item.ant-menu-item-selected .ant-menu-item-icon {
    @apply text-secondary-black;
  }

  .sidebar-menu.ant-menu .ant-menu-item.ant-menu-item-selected::after {
    content: '';
    background: url('../../assets/images/icons/rectangle.svg') no-repeat center center;
    transform: translateY(-50%);
    @apply absolute right-0 top-1/2 inline-block h-10 w-1 bg-contain;
  }

  .sidebar-menu.ant-menu .ant-menu-item.ant-menu-item-active .ant-menu-title-content,
  .sidebar-menu.ant-menu .ant-menu-item.ant-menu-item-active .ant-menu-item-icon {
    @apply text-secondary-black;
  }
}
