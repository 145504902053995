@tailwind components;

@layer components {

  .ctv-card.ant-card {
    box-shadow: 0px 10px 15px -8px rgba(238, 147, 165, 0.25);
  }

  .ctv-card .ant-card-body {
    @apply p-0;
  }
}
