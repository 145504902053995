@tailwind components;

@layer components {
  .ctv-password-rules {
    .rule-block {
      @apply inline-flex items-center justify-start gap-1.5;
    }
    svg {
      @apply stroke-primary-gray-500;
    }
    .rule-text {
      @apply text-base-sm font-medium leading-tight text-primary-gray-500;
    }
    .valid {
      svg {
        @apply stroke-system-success-500;
      }
      .rule-text {
        @apply text-secondary-black;
      }
    }
  }
}
