@tailwind components;

@layer components {
  .reports-table-wrapper {
    .ctv-table .ant-table-column-sorters .ant-table-column-title {
      @apply min-w-max flex-grow-0 text-left text-base-xs font-medium text-primary-gray-500;
    }
  }
  .ctv-create-report .ctv-email-form .email-list {
    @apply w-168;
  }
}
