@tailwind components;

@layer components {
  .ctv-progress {
    .step-1 {
      left: 24px;
    }
    .step-2 {
      left: 36px;
    }
    .step-3 {
      left: 48px;
    }
    .step-4 {
      left: 60px;
    }
    .step-5 {
      left: 72px;
    }
    .step-6 {
      left: 84px;
    }
    .step-7 {
      left: 96px;
    }
    .step-8 {
      left: 108px;
    }
    .step-9 {
      left: 120px;
    }
    .step-10 {
      left: 132px;
    }
  }
}
