.widget-container .ctv-card.ant-card {
  @apply rounded-none border-b-0 border-l-0 border-primary-gray-100 border-t-transparent bg-transparent shadow-none;
}

.widget-container .card-item:first-child .ctv-card.ant-card {
  @apply rounded-tl-lg;
}

.widget-container .ant-card .ant-card-body {
  @apply m-2 flex flex-col justify-around py-4 pl-3;
  height: calc(100% - 16px);
}

.widget-container .action-container .ant-card-body {
  @apply pl-0;
  stroke-width: 1.5px;
}

.widget-container .selected .ant-card-body,
.widget-container .ant-card-body:hover {
  @apply rounded;
  background-color: theme('colors.primary.gray.50');
}

.widget-container .selected {
  &::before {
    content: '';
    mask: url('../../assets/images/icons/rectangle_long.svg') no-repeat center center;
    transform: translateY(-50%);
    background-color: var(--ctv-widget-color);
    @apply absolute left-1.5 top-1/2 inline-block h-24 w-2 bg-contain;
  }
}

.ant-popover .ant-popover-arrow {
  @apply hidden;
}

.ant-popover .ant-popover-content .ant-popover-inner {
  @apply p-0;
}

.ant-popover .ant-popover-content .ant-popover-inner .ant-card-head {
  @apply text-base font-semibold leading-snug;
  background-color: theme('colors.primary.gray.25');
}

.ant-popover .ant-popover-content .ant-popover-inner .ant-card-body {
  @apply h-[212px] px-6 pb-6 pt-4;
}

.ant-popover .ctv-progress-line .ant-progress-bg {
  @apply bg-primary-electric-500;
}
