@tailwind components;

@layer components {
  .select-container.text-prefix .filter-dropdown .ant-select-selector {
    padding-left: 105px !important;
  }

  .filter-dropdown-select-dropdown .ant-select-item.ant-select-item-option-selected {
    @apply bg-white font-normal;

  }
  .filter-dropdown-select-dropdown .ant-select-item.ant-select-item-option-selected .ant-select-item-option-state {
      color: theme('colors.secondary.black');
    }
}

