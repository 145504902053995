.button-container .ant-btn {
  @apply w-[232px];
  height: 40px !important;
}

.ant-select-item .ant-select-item-option-content {
  @apply flex justify-between;
}

.ant-select-item .ant-select-item-option-content .actions svg {
  @apply h-5 w-5;
  stroke-width: 1.5px;
}
