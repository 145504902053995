.ctv-user-account-button {
  border-color: theme('colors.primary.punch.300') !important;
}

.ant-dropdown-open .ctv-user-account-button {
  border: 1px solid theme('colors.primary.punch.300') !important;
  box-shadow: 0 0 0 4px rgba(238, 147, 165, 0.25) !important;

  &:hover {
    background-color: theme('colors.white') !important;
  }
}

.ant-dropdown-menu.ant-dropdown-menu-root {
  @apply max-h-[332px] w-64 overflow-auto border border-t-0 p-1.5;
  border-radius: 0 0 8px 8px;
  border-color: theme('colors.primary.gray.100');
}

.search-container .ant-input-affix-wrapper .ant-input::placeholder {
  @apply leading-tight;
  color: theme('colors.primary.gray.500');
}

.search-container .ant-input-affix-wrapper-focused.ant-input-outlined {
  border-color: theme('colors.primary.punch.300') !important;
  box-shadow: 0 0 0 4px rgba(238, 147, 165, 0.25) !important;
}

.search-container .ant-input-outlined:hover {
  border-color: theme('colors.primary.gray.100');
}

.search-container .ant-input-affix-wrapper .ant-input-prefix {
  svg {
    color: theme('colors.primary.gray.500');
  }
}

.ant-dropdown-menu .ant-dropdown-menu-item-group .ant-dropdown-menu-item-group-title {
  @apply px-1.5 text-base-sm font-normal;
  color: theme('colors.secondary.black');
}

.ant-dropdown-menu .ant-dropdown-menu-item-group .ant-dropdown-menu-item-group-list {
  @apply m-0;
}

.ant-dropdown-menu .ant-dropdown-menu-item-group .ant-dropdown-menu-item {
  @apply h-10 justify-between rounded pl-6 pr-2 leading-tight;
}

.ant-dropdown-menu .ant-dropdown-menu-item .ant-dropdown-menu-title-content {
  @apply flex items-center justify-between text-base-sm font-normal;
}

.placeholder-item {
  @apply flex w-full items-center justify-center text-base-sm font-normal;
  color: theme('colors.secondary.black');
}
