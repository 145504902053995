@tailwind components;

@layer components {
  .ctv-spinner svg {
    animation: spin 1s linear infinite;
  }

  .ctv-spinner.ant-spin .ant-spin-dot {
    @apply h-10 w-10;
  }

  @keyframes spin {
    0% {
      transform: rotate(-0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
}
