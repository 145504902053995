@tailwind components;

@layer components {
  .layout-menu.ant-menu {
    @apply mr-3 bg-primary-gray-50;
    border: none !important;
    --tw-bg-opacity: 0;
  }

  .layout-menu.ant-menu .ant-menu-item {
    width: 232px;
  }

  .layout-menu.ant-menu .ant-menu-item .ant-menu-title-content,
  .layout-menu.ant-menu .ant-menu-item .ant-menu-item-icon {
    @apply text-base-sm font-medium text-secondary-black;
  }

  .layout-menu.ant-menu .ant-menu-item.ant-menu-item-selected .ant-menu-title-content,
  .layout-menu.ant-menu .ant-menu-item.ant-menu-item-selected .ant-menu-item-icon {
    @apply text-primary-electric-500;
  }

  .layout-menu.ant-menu .ant-menu-item.ant-menu-item-selected {
    @apply bg-primary-electric-50;
  }

  .layout-menu.ant-menu .ant-menu-item:not(.ant-menu-item-selected):active {
    @apply bg-primary-electric-50;
  }
}
