@tailwind components;

@layer components {
  .ctv-switch {
    @apply h-5 w-9 min-w-10 bg-primary-gray-100;
    .ant-switch-handle {
      @apply h-4 w-4;
    }
  }

  .ctv-switch.ant-switch-checked {
    @apply bg-primary-electric-500;
  }

  .ctv-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    @apply bg-primary-electric-300;
  }

  .ctv-switch.ant-switch-disabled {
    @apply bg-primary-gray-50 opacity-100;
    .ant-switch-handle {
      @apply bg-primary-gray-25;
    }
  }
}
