@tailwind components;

@layer components {
  .xandr-events-table-wrapper {
    .ctv-table .ant-table-column-sorters .ant-table-column-title {
      @apply min-w-max flex-grow-0 text-left text-base-xs font-medium text-primary-gray-500;
    }
  }
  .ctv-chart-tooltip {
    div {
      span:nth-of-type(1) {
        display: none !important;
      }
      span:nth-of-type(3) {
        float: none !important;
        font-weight: 600;
      }
    }
  }
}
