@tailwind components;

@layer components {
  .ctv-grid.MuiDataGrid-root {
    @apply w-full bg-white;
    --DataGrid-rowBorderColor: theme('colors.white') !important;
  }

  .ctv-grid.MuiDataGrid-root .MuiDataGrid-main {
    @apply pb-0 pl-3 pr-3 pt-3;
  }

  .ctv-grid.MuiDataGrid-root .MuiDataGrid-main .MuiDataGrid-virtualScroller {
    @apply overflow-hidden;
  }

  .ctv-grid .MuiDataGrid-topContainer {
    @apply rounded-lg bg-primary-gray-50;
    --DataGrid-rowBorderColor: transparent;
  }

  .ctv-grid .MuiDataGrid-columnHeaders {
    @apply h-8 max-h-8 min-h-8 rounded-lg leading-8 text-primary-gray-500;

    & > div {
      background-color: transparent !important;
    }
  }

  .ctv-grid .MuiDataGrid-columnHeader {
    @apply h-8 max-h-8 min-h-8;
    border-right: 1px solid theme('colors.primary.gray.100') !important;

    &:focus {
      outline: none !important;
    }

    & .MuiDataGrid-columnHeaderTitle {
      @apply text-left text-base-xs font-medium;
    }
  }

  .ctv-grid .MuiDataGrid-columnHeader ~ .MuiDataGrid-columnHeader {
    border: none !important;
  }

  .ctv-grid .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader {
    @apply bg-transparent text-primary-gray-500;

    &:focus-within {
      outline: none;
    }
  }

  .ctv-grid .MuiDataGrid-iconButtonContainer {
    visibility: visible !important;
  }

  .ctv-grid .MuiDataGrid-sortIcon {
    @apply h-4 w-4;
    opacity: 1 !important;
    color: theme('colors.primary.gray.500');
  }

  .ctv-grid .MuiDataGrid-columnSeparator {
    display: none !important;
  }

  .ctv-grid .MuiTablePagination-selectLabel,
  .MuiTablePagination-input {
    display: none !important;
  }

  .ctv-grid .MuiDataGrid-footerContainer .MuiTablePagination-root {
    @apply w-full overflow-hidden;
  }

  .ctv-grid
    .MuiDataGrid-footerContainer
    .MuiTablePagination-root
    .MuiTablePagination-displayedRows {
    @apply absolute;
  }

  .ctv-grid .MuiDataGrid-actionsCell svg {
    @apply h-5 w-5;
    stroke-width: 1.5px;
  }

  .ctv-grid .MuiDataGrid-cell {
    @apply text-left text-base-sm font-normal leading-5;
    padding: 16px 12px !important;
    line-height: 20px !important;
    &:focus {
      outline: none;
    }
  }

  .ctv-grid .MuiDataGrid-cell--textRight {
    padding-right: 30px !important;
  }

  .ctv-grid .MuiDataGrid-row .MuiDataGrid-cell {
    border-right: 1px solid theme('colors.primary.gray.100');
  }

  .ctv-grid .MuiDataGrid-row .MuiDataGrid-cell ~ .MuiDataGrid-cell {
    border: none;

    &:focus {
      outline: none;
    }
  }

  .ctv-grid .MuiDataGrid-row:hover {
    background-color: theme('colors.white') !important;
  }

  .ctv-grid .MuiInputBase-input {
    &:focus {
      background-color: theme('colors.white');
    }
  }

  //status row styling
  .status-cell {
    @apply h-[28px] w-[67px] gap-1 rounded-xl px-3 py-1 text-left text-base-sm font-medium leading-5;
  }

  .status-cell.active {
    @apply bg-system-success-75 text-system-success-500;
  }

  .status-cell.confirmed {
    @apply bg-system-success-75 text-system-success-500;
  }

  .status-cell.invited {
    @apply bg-system-warning-75 text-system-warning-500;
  }
}
