@tailwind components;

@layer components {
  .ctv-performance-table {
    .ctv-tabs.ant-tabs .ant-tabs-nav {
      @apply bg-white;
    }

    .ctv-tabs.ant-tabs .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab {
      @apply py-4 pt-6 text-base font-medium leading-snug;
      color: theme('colors.primary.gray.500');
      border-radius: 0;
      box-shadow: none;
    }

    .ctv-tabs.ant-tabs-top > .ant-tabs-nav,
    .ctv-tabs.ant-tabs-bottom > .ant-tabs-nav,
    .ctv-tabs.ant-tabs-top > div > .ant-tabs-nav,
    .ctv-tabs.ant-tabs-bottom > div > .ant-tabs-nav {
      @apply mb-0;
    }
  }

  .ctv-analytics-table {
    .second-dimension-row,
    .second-dimension-row .ant-table-cell-fix-left,
    .second-dimension-row .ant-table-cell-fix-right {
      @apply bg-secondary-gray;
    }

    .total-row {
      .ant-table-cell {
        @apply border-t border-primary-gray-100 font-semibold;
      }
    }
  }
}
