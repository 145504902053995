@import './assets/fonts/fonts.scss';
@import 'components/components.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body * {
    font-family: Inter, sans-serif !important;
    font-size: theme('fontSize.base');
  }

  #root {
    min-height: 100vh;
  }

  .page-background {
    @apply bg-contain bg-[top_0_right_0] bg-no-repeat;
  }

  .page-pattern-background {
    @apply bg-pattern bg-auto bg-repeat;
  }

  .unauthorized-background {
    @apply bg-unauthorizedLeftBg bg-contain bg-left bg-no-repeat;
  }
}
