@tailwind components;

@layer components {
  .ctv-segmented.ant-segmented,
  .ctv-segmented.ant-segmented.ant-segmented-lg,
  .ctv-segmented.ant-segmented.ant-segmented-sm {
    @apply rounded-full p-1;

    .ant-segmented-thumb,
    .ant-segmented-item {
      @apply rounded-full;
      .ant-segmented-item-label {
        @apply h-8 px-5 text-base-sm font-medium leading-8;
      }
    }
  }
}
