@tailwind components;

@layer components {
  .ctv-user-menu-button.ant-btn {
    min-width: 64px !important;
  }

  .ctv-user-menu-button.ant-btn.ant-dropdown-open {
    @apply border-2 border-primary-punch-300;
    box-shadow: 0px 0px 0px 4px rgba(238, 147, 165, 0.25) !important;
    &:hover {
      border-color: theme('colors.primary.punch.300') !important;
    }
  }

  .ctv-user-menu-button.ant-btn:hover {
    border: 1px solid theme('colors.primary.gray.50') !important;
  }
}
