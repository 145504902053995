@tailwind components;

@layer components {
  .input {
    @apply border-primary-gray-100
      placeholder:text-text-500
      focus-within:z-auto
      focus-within:border-primary-punch-300
      focus-within:shadow-input-focus-shadow
      focus-within:outline-0
      hover:z-auto
      hover:border-primary-gray-500
      hover:focus-within:border-primary-punch-300;
  }

  .ctv-picker.ant-picker {
    @apply input;

    &.ctv-picker-preset {
      .ant-picker-input:nth-last-of-type(2) {
        @apply hidden;
      }
      .ant-picker-input input {
        @apply pl-2 text-start;
      }
    }

    .ant-picker-active-bar {
      @apply hidden;
    }
  }

  .ctv-picker.ctv-picker-root {
    @apply w-72 bg-[url('./assets/images/icons/calendar.svg')] bg-[left_9px_top_9px] bg-no-repeat pl-7 pr-3;

    .ant-picker-suffix {
      @apply w-4 [&>svg]:stroke-secondary-black;
    }

    .ant-picker-input input {
      @apply text-center;
    }
  }

  .ctv-picker.ant-picker-disabled {
    @apply bg-primary-gray-25
      placeholder:text-text-200;
  }

  .ctv-picker.ctv-picker__invalid {
    @apply border-system-danger-500
      placeholder:text-text-50
      focus-within:z-auto
      focus-within:border-primary-punch-300
      focus-within:shadow-input-focus-shadow
      focus-within:outline-0;
  }

  .ctv-picker-root {
    .ant-picker-panel-container {
      @apply px-2 pb-1 pt-2;

      .ant-picker-presets ul li {
        @apply rounded-full
          py-1
          pl-3 pr-1 text-base-sm;

        &:hover {
          @apply bg-primary-electric-50;
        }
      }

      .ant-picker-presets ul li + li {
        @apply mt-0;
      }

      .ant-picker-panels {
        @apply pt-2;
      }

      .ant-picker-header,
      .ant-picker-footer {
        @apply border-none;
      }

      .ant-picker-date-panel .ant-picker-content th {
        @apply text-base-xs
          text-text-500;
      }

      .ant-picker-header-view > button:hover {
        @apply text-primary-electric-500;
      }
    }

    .ant-picker-range-separator {
      @apply p-1;
    }

    .ant-picker-cell {
      @apply py-1;

      &::before {
        @apply h-9;
      }

      .ant-picker-cell-inner {
        @apply h-7
          min-w-7
          rounded-full
          leading-7;
      }

      &.ant-picker-cell-today .ant-picker-cell-inner::before {
        @apply rounded-full border-primary-electric-500;
      }

      &.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-end),
      &.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-start),
      &.ant-picker-cell-range-start,
      &.ant-picker-cell-range-end {
        .ant-picker-cell-inner {
          @apply rounded-full;
        }
      }

      &.ant-picker-cell-in-view.ant-picker-cell-selected:not(.ant-picker-cell-disabled),
      &.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled),
      &.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled) {
        .ant-picker-cell-inner {
          @apply bg-primary-electric-500;
        }
      }

      &.ant-picker-cell-in-view.ant-picker-cell-in-range:not(.ant-picker-cell-disabled),
      &.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled),
      &.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled) {
        &::before {
          @apply bg-primary-electric-50;
        }
      }

      &.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled):not(
          .ant-picker-cell-range-end
        ):before {
        @apply start-0 rounded-l-full;
      }

      &.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled):not(
          .ant-picker-cell-range-start
        ):before {
        @apply end-0 rounded-r-full;
      }
    }
  }
}
