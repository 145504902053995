@tailwind components;

@layer components {
  .ctv-tabs .ant-tabs-nav {
    @apply h-10 rounded-full bg-primary-gray-50 p-1 px-5;
  }

  .ctv-tabs .ant-tabs-nav-list {
    @apply gap-4;
  }
  .ctv-tabs.ant-tabs-top > .ant-tabs-nav::before {
    border: 0 none;
  }
  .ctv-tabs > .ant-tabs-nav .ant-tabs-tab {
    @apply inline-flex h-8 items-center justify-center rounded-full border-0 bg-transparent px-1 text-base-sm font-medium text-secondary-black;
  }
  .ctv-tabs > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
    @apply rounded-full bg-white shadow-shadow-md;
    .ant-tabs-tab-btn {
      @apply text-secondary-black;
    }
  }
  .ctv-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    overflow: visible;
  }
  .ctv-tabs > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-disabled {
    @apply text-primary-gray-200;
  }

  .ctv-dashboard-charts .ctv-tabs > .ant-tabs-nav .ant-tabs-tab {
    @apply font-normal;
  }

  .ctv-tabs.ant-tabs .ant-tabs-nav {
    @apply bg-white;
  }

  .ctv-tabs.ant-tabs .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab {
    @apply py-4 pt-6 text-base font-medium leading-snug;
    color: theme('colors.primary.gray.500');
    border-radius: 0;
    box-shadow: none;
  }

  .ctv-tabs.ant-tabs .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active {
    &::before {
      content: '';
      @apply absolute top-[-4px] inline-block  border-b-[6px] border-primary-electric-500;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      width: inherit;
    }
    @apply w-full;
  }
}
