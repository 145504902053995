@tailwind components;

@layer components {
  .ctv-checkbox .ant-checkbox-input,
  .ctv-checkbox .ant-checkbox-inner {
    @apply h-5 w-5 rounded border-secondary-black;
  }

  .ctv-checkbox .ant-checkbox-group-item:hover .ant-checkbox-inner,
  .ctv-checkbox.ant-checkbox-wrapper:hover .ant-checkbox-inner {
    @apply border-primary-electric-300; //hover wasn't specified
  }

  .ctv-checkbox .ant-checkbox-wrapper-checked.ant-checkbox-group-item:hover .ant-checkbox-inner,
  .ctv-checkbox.ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner {
    @apply border-primary-electric-300 bg-primary-electric-300 #{!important};
  }

  .ctv-checkbox .ant-checkbox-checked .ant-checkbox-inner {
    @apply border-primary-electric-500 bg-primary-electric-500;
  }
  .ctv-checkbox .ant-checkbox-checked .ant-checkbox-inner::after {
    & {
      background-image: url('/assets/images/icons/check.svg');
      background-position: center;
    }
    @apply inset-0 top-0 block h-5 w-5 transform-none border-0;
  }

  .ctv-checkbox .ant-checkbox-disabled .ant-checkbox-inner,
  .ctv-checkbox
    .ant-checkbox-wrapper-checked.ant-checkbox-wrapper-disabled.ant-checkbox-group-item:hover
    .ant-checkbox-inner {
    @apply border-primary-gray-200 bg-primary-gray-25 #{!important};
  }

  .ctv-checkbox:hover .ant-checkbox-disabled .ant-checkbox-inner {
    @apply border-primary-gray-200;
  }
  .ctv-checkbox .ant-checkbox-indeterminate .ant-checkbox-inner:after {
    @apply bg-primary-electric-300;
  }

  .ctv-checkbox .ant-checkbox-indeterminate .ant-checkbox-inner:after {
    & {
      background-image: url('/assets/images/icons/dash.svg');
      background-position: center;
    }
    @apply inset-0 top-0 block h-5 w-5 transform-none rounded border-0 bg-primary-electric-500 bg-no-repeat;
  }
}
