@tailwind components;

@layer components {
  .input {
    @apply relative h-[40px] rounded-lg border-primary-gray-100 bg-secondary-white px-4 text-base-sm text-text-100
    placeholder:text-text-500
    hover:z-auto hover:border-primary-gray-500
    focus:z-auto focus:border-primary-punch-300 focus:shadow-input-focus-shadow focus:outline-0;
    &:last-child {
      @apply rounded-lg;
    }
  }

  .ctv-input.ant-input,
  .ctv-input .ant-input-number-input {
    @apply input h-auto py-2;
  }
  .ctv-input.ant-input-number-outlined {
    @apply relative h-[40px] rounded-lg  focus-within:border-primary-punch-300 focus-within:outline-0 hover:z-auto
    focus:z-auto focus:border-primary-punch-300 focus:shadow-input-focus-shadow focus:outline-0;
    box-shadow: none;
    &:last-child {
      @apply rounded-lg;
    }
    &:hover {
      @apply border-primary-gray-500 focus-within:border-primary-punch-300 focus:border-primary-punch-300;
    }
  }

  .ctv-input.ant-input-group-wrapper input {
    @apply input pl-10;
  }

  .ctv-input.ant-input-password {
    @apply rounded-lg bg-transparent p-0
      hover:z-auto hover:border-primary-gray-500;
  }

  .ctv-input.ant-input-password.ant-input-outlined:focus-within {
    box-shadow: none;
    border-color: transparent;
  }

  .ctv-input.ant-input-password input {
    @apply input pr-10;
    &:focus {
      outline: 1px theme('colors.primary.punch.300') solid !important;
      box-shadow: 0 0 0 4px rgba(238, 147, 165, 0.25) !important;
    }
  }

  .ctv-input[readonly],
  .ctv-input.ant-input-disabled,
  .ctv-input.ant-input-number-disabled input[disabled] {
    @apply bg-primary-gray-25
      placeholder:text-text-200 hover:border-primary-gray-100 focus-within:hover:border-primary-gray-100 focus-within:hover:shadow-none focus:border-primary-gray-100 focus:shadow-none focus:outline-0;
  }

  .ctv-input.ctv-input__large,
  .ctv-input.ctv-input__large.ant-input-group-wrapper input,
  .ctv-input.ctv-input__large.ant-input-password input {
    @apply h-12 text-base-md;
  }
  .ctv-input.ctv-input__large.ant-input-password input {
    @apply bg-transparent;
  }

  .ctv-input.ctv-input__invalid,
  .ctv-input.ctv-input__invalid.ant-input-group-wrapper input,
  .ctv-input.ctv-input__invalid.ant-input-password input {
    @apply border-system-danger-500
      placeholder:text-text-50
      focus:z-auto focus:border-primary-punch-300 focus:shadow-input-focus-shadow focus:outline-0;
  }

  .ctv-input.ant-input-group-wrapper .ant-input-group-addon {
    @apply absolute left-[12px] z-10 flex h-full w-[16px] items-center border-0 bg-transparent p-0;
  }

  .ctv-input.ant-input-password .ant-input-suffix {
    @apply absolute right-[12px] z-10 flex h-full w-[16px] items-center border-0 bg-transparent p-0;
  }
}
