@tailwind components;

@layer components {
  .ctv-chip {
    @apply inline-flex h-7 items-start justify-start border-0 bg-transparent px-0;
    .ctv-chip-text {
      @apply flex h-7 items-center justify-center rounded-bl-full rounded-tl-full border border-primary-gray-100 bg-primary-gray-50 px-3 text-base-xs font-medium text-secondary-black hover:cursor-pointer hover:bg-primary-gray-100;
    }
  }
  .ctv-chip.ant-tag .ant-tag-close-icon {
    @apply ms-0 flex h-7 w-8 items-center justify-center rounded-br-full rounded-tr-full border-b border-r border-t border-primary-gray-100  bg-primary-gray-50 px-2 hover:cursor-pointer hover:bg-primary-gray-100;
    svg {
      @apply fill-secondary-black;
    }
  }
  .ctv-chip.ctv-chip-active {
    .ctv-chip-text {
      @apply z-10  border-primary-punch-300 shadow-input-focus-shadow;
    }
  }
}
