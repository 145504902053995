@tailwind components;

@layer components {
  .ctv-modal {
    width: fit-content !important;
  }
  .ctv-modal .ant-modal-content {
    @apply p-0;
  }
}
