@tailwind components;

@layer components {
  .ctv-tooltip .ant-tooltip-inner {
    @apply rounded-lg p-3;
    background: linear-gradient(180deg, rgba(22, 23, 26, 0.75) 0%, rgba(22, 23, 26, 0.9) 100%);
  }
  .ctv-tooltip .ant-tooltip-arrow {
    color: rgba(22, 23, 26, 0.8);
    border-color: rgba(22, 23, 26, 0.8);
    --antd-arrow-background-color: rgba(22, 23, 26, 0.8);
  }
}
