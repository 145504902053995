@tailwind components;

@layer components {
  .select-container {
    display: flex;
    width: 100%;

    &.text-prefix {
      .ant-select-selector {
        padding-left: 55px !important;
      }

      &::before {
        content: attr(data-prefix);
        width: 0px;
        position: relative;
        top: 12px;
        left: 15px;
        z-index: 1;
        font-size: 12px;
        color: theme('colors.primary.gray.500');
      }
    }

    &.icon-prefix {
      .ant-select-selector {
        padding-left: 45px !important;
      }

      .prefix-icon {
        position: relative;
        width: 0px;
        z-index: 1;
        top: 20px;
        left: 12px;
        transform: translateY(-50%);

        height: 100%;
      }
    }

    .ctv-select.ant-select {
      @apply h-[40px] w-full;

      &.ant-select-focused .ant-select-selector,
      &.ant-select-open .ant-select-selector {
        border-color: theme('colors.primary.punch.300') !important;
        box-shadow: 0 0 0 4px rgba(238, 147, 165, 0.25) !important;
      }
    }
    .ctv-select.ant-select.bg-white > .ant-select-selector {
      @apply bg-secondary-white;
    }

    .ctv-select.ant-select > .ant-select-selector {
      @apply h-[40px] w-full rounded-lg border-primary-gray-100 bg-secondary-white text-base-sm text-text-100;

      &:hover {
        border: 1px solid theme('colors.primary.gray.500') !important;
        z-index: auto;
      }
    }

    .ctv-select.ant-select.ant-select-disabled > .ant-select-selector {
      @apply bg-primary-gray-25;
      &:hover {
        border: 1px solid theme('colors.primary.gray.100') !important;
      }
    }

    .ctv-select.ant-select > .ant-select-selector > .ant-select-selection-item {
      @apply py-[5px] text-base-sm text-text-100;
    }

    .ctv-select.ant-select > .ant-select-selector > .ant-select-selection-placeholder {
      @apply py-[5px] text-base-sm text-text-50;
    }

    .ctv-select.ant-select.ant-select-disabled
      > .ant-select-selector
      > .ant-select-selection-placeholder {
      @apply py-[5px] text-base-sm text-text-200;
    }

    .ctv-select.ant-select.ant-select-disabled > .ant-select-arrow {
      color: theme('colors.primary.gray.200');
    }

    .ctv-select.ant-select > .ant-select-arrow {
      @apply text-text-100;
    }

    .ctv-select.ant-select.ant-select-open > .ant-select-selector {
      @apply z-auto border-primary-gray-500 shadow-input-focus-shadow;
    }

    .ctv-select-dropdown.ant-select-dropdown .ant-select-item-option {
      @apply h-[38px] px-4 py-2;
    }

    .ctv-select-dropdown.ant-select-dropdown .ant-select-item-option-selected {
      @apply bg-secondary-white font-normal text-text-100;
    }

    .ctv-select-dropdown.ant-select-dropdown .ant-select-item-option-disabled {
      @apply bg-primary-gray-25 font-normal text-text-50;
    }
  }
}

// .ctv-select-dropdown
.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  @apply bg-secondary-white;

  svg {
    @apply fill-primary-electric-500;
  }
}

.ctv-option .ant-select-item-option-content {
  @apply text-base-sm;
}

.ctv-option.ctv-checkbox .ant-checkbox-group-item {
  @apply pl-1;
  span {
    @apply text-base-sm;
  }
}
