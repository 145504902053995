@tailwind components;

/* used a global style for scrollbars  */
/* in case it is needed to be modified - it is used in checkbox groups (e.g. InviteMembersModal )*/

::-webkit-scrollbar {
  width: 0.875em; /* Total width including `border-width` of scrollbar thumb */
  height: 0.875em;
}
::-webkit-scrollbar-thumb {
  border: 0.25em solid rgba(0, 0, 0, 0); /* Transparent border together with `background-clip: padding-box` does the trick */
  background-clip: padding-box;
  -webkit-border-radius: 0.5em;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.025);
}
::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}
